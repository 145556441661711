export default [
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    Permission: '',
  },

  {
    title: 'Files',
    route: 'file-list',
    icon: 'FolderIcon',
    Permission: '',
  },

  {
    title: 'Users',
    icon: 'UsersIcon',
    Permission: 'users_access',
    route: 'user-list',
  },

  {
    title: 'Sign Out',
    route: 'signout',
    icon: 'LogOutIcon',
    Permission: '',
  },
];
